import React from "react";
import { ButtonArrowRight } from "../components/UI/Button";
import Layout from "../Layout";
import SEO from "../components/seo";

const NotFoundPage = ({ location }) => (
    <Layout location={location}>
        <SEO title="404 Not found" />
        <section className="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-height-1-1" data-uk-height-viewport="offset-top: true">
            <div className="uk-container uk-container-xsmall">
                <div className="uk-padding">
                    <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                        <strong>&mdash;</strong> 404 This page does not exist
                    </p>
                    <h1 className="uk-margin-small-top uk-margin-large-bottom">The easiest way to solve a problem is to deny it exists.</h1>
                    <ButtonArrowRight
                        url={`/portfolio/`}
                        label="See my work"
                        css={`cta-button uk-text-astro-darker`}
                        color={`rgba(44, 44, 43, 1)`} />
                </div>
            </div>
        </section>
    </Layout>
)

export default NotFoundPage
